import $ from "jquery";

$(function() {
    $(".bl-accordionelement").on("click", ".ctracctitle", function() {
        const $accitem = $(this).closest(".bl-accordionelement");
        const isexpanded = $accitem.hasClass("expanded");

        const $details = $accitem.find(".ctracccontent");
        const animspeed = 200;

        if(!isexpanded) {
            $accitem.addClass("expanded");
            $details.slideDown(animspeed);    
        } else {
            $accitem.removeClass("expanded");
            $details.slideUp(animspeed);    
        }
    });
});
import $ from "jquery";

$(function() {
    const $iframemodal = $("#iframemodal");

    function closeModal() {
        $iframemodal.hide();
    }

    function showModal(url) {
        const $iframe = $iframemodal.find("iframe");
        $iframe.attr("src", url);
        $iframemodal.show();
    }

    $iframemodal.find(".modalbg").on("click", closeModal);
    $iframemodal.find(".icon-modalclose").on("click", closeModal);

    $("a[href*='mylocalina.ch']").on("click", function(ev) {
        const $a = $(this);
        const url = $a.attr("href");
        showModal(url);
        ev.preventDefault();
    });
});
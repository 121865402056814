//Polyfill until https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_scroll-driven_animations are implemented in browsers.
//https://developer.mozilla.org/en-US/docs/Web/CSS/view-timeline#browser_compatibility

import $ from "jquery";

function clamp(number, min, max) {
    return Math.min(Math.max(number, min), max);
}
  
$(function() {
    const $elements = $(".scrollanimation");

    function updateScrollProgress() {
        //Update scrollprogress CSS variable on elements

        //const scrollY = window.scrollY;
        const viewportH = window.innerHeight;

        $elements.each(function() {
            const element = this;
            const rect = element.getBoundingClientRect();
            const estart = rect.top-viewportH; //Ist zu Beginn positiv. Wenn das 0 wird, dann beginnt das Einblenden.
            const eend = rect.bottom; //Ist zu Beginn positiv. Wenn das 0 wird, dann endet das Einblenden.
            const effectHeight = eend-estart; //konstant

            const progressPercent = clamp((estart*-1)/effectHeight, 0, 1);
            //console.log("start="+estart);
            //console.log("end="+eend);
            //console.log("diff="+ediff);

            //console.log(progressPercent);
            element.style.setProperty('--scrollpercent', progressPercent);
        });
    }
    
    $(window).on("scroll", function() {
        window.requestAnimationFrame(updateScrollProgress);
    });
    $(window).on("load", updateScrollProgress);
    updateScrollProgress();

});
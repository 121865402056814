import $ from "jquery";

//npm install swiper
import Swiper from 'swiper/bundle';

$(function() {
    $(".bl-imgsandtext").each(function() {
        const $bl = $(this);
        const swiper = initSwiper($bl);
        if($bl.hasClass("specialmode-rooms")) {
            initSpecialRooms($bl, swiper);
        }
    });
});

function initSwiper($bl) {
    const swiperConfig = {
        autoHeight: true,
        
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    };
    if($bl.hasClass("specialmode-rooms")) {
        swiperConfig.effect = "fade";
    }
    const swiper = new Swiper($bl.find(".swiper").get(0), swiperConfig);
    return swiper;
}

function initSpecialRooms($bl, swiper) {
    console.log(swiper);
    let linkIdx=0;
    //const swiper = $bl.find(".swiper").get(0).swiper;
    $bl.find(".ctrtext").find("a").each(function() {
        const $a = $(this);
        const curLinkIdx = linkIdx;
        $a.on("mouseenter", function() {
            swiper.slideTo(curLinkIdx);
        });
        linkIdx++;
    });
}


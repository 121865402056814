//npm install swiper
import Swiper from 'swiper/bundle';

import 'swiper/css/bundle';

import $ from "jquery";

$(function() {
    $(".bl-infohslider").each(function() {
        const $bl = $(this);
        initTipps($bl);
    });
});

function initTipps($bl) {
    const swiper = new Swiper($bl.find(".swiper").get(0), {
        loop: false,
        autoHeight: false,
        slidesPerView: "auto",
        spaceBetween: 0,
        navigation: {
            nextEl: $bl.find(".icon-arrowright").get(0),
            prevEl: $bl.find(".icon-arrowleft").get(0),
        },        
        mousewheel: {
            enabled: true,
            forceToAxis: true
        }
    });
}

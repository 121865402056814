import $ from "jquery"

$(function() {
    /*
    const $menubutton = $("#menubutton");
    const $desktopmenu = $("#desktopmenu");
    const $body = $("body");
    $menubutton.on("click", function() {
        const newvisible = !$desktopmenu.hasClass("visible");
        $desktopmenu.toggleClass("visible", newvisible);
        $body.toggleClass("desktopmenuvisible", newvisible);
    });
    */

    const $mobilemenu = $("#mobilemenu");
    const $hamburger = $("#hamburger-mobilemenu");
    const $body = $("body");

    $hamburger.on("click", function() {
        toggleMobileMenu();
    });

    function toggleMobileMenu() {
        const mobileMenuVisible = $mobilemenu.hasClass("visible");
        if(mobileMenuVisible) {
            hideMobileMenu();
        } else {
            showMobileMenu();
        }
    }

    function showMobileMenu() {
        $mobilemenu.addClass("visible");
        $hamburger.addClass("is-active");
        $body.addClass("mobilemenuvisible");
    }

    function hideMobileMenu() {
        $mobilemenu.removeClass("visible");
        $hamburger.removeClass("is-active");
        $body.removeClass("mobilemenuvisible");
    }

    /*
    function enhanceHtml() {
        $mobilemenu.find("li.hassubmenu > a").append('<svg class="icon icon-arrowdown"><use xlink:href="#icon-arrowdown"></use></svg>');
    }
    enhanceHtml();
    */

    let imgOverlayZIndex = 1;
    function setupImageOverlay() {
        $mobilemenu.find("ul").first().on("mouseenter", "li", function() {
            const $li = $(this);
            const ressourceId = $li.data("rid");
            const $overlayImg = $mobilemenu.find(".overlayimg[data-rid="+ressourceId+"]");
            $overlayImg.hide();
            $overlayImg.css("z-index", imgOverlayZIndex);
            $overlayImg.fadeIn(400);
            imgOverlayZIndex++;
        });
    }
    setupImageOverlay();
});


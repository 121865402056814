import $ from "jquery";

$(function () {
    $(".bl-historyteaser").each(function() {
        const bl = this;
        defineAnimation(bl);
    });
});

function defineAnimation(bl) {
    const scrollytellingCtr = bl.querySelector(".ctrscrollytelling");

    const timeline = new ViewTimeline({
        subject: scrollytellingCtr,
        axis: "block",
        inset: [CSS.percent(0), CSS.percent(0)]
    });

    const items = bl.querySelectorAll(".item");
    const numItems = items.length;

    function addAnimation(itemIdx, el, animation, offsetStart, offsetEnd, options)
    {
        let shiftedOffsetStart;
        let shiftedOffsetEnd;
        if(itemIdx == 0) {
            shiftedOffsetStart = 0;
            shiftedOffsetEnd = 1;
        } else {
            const shiftMultiplicator = 0.9/numItems;
            const shiftAdd = 0.1+(itemIdx-1)*shiftMultiplicator;
            shiftedOffsetStart = offsetStart*shiftMultiplicator+shiftAdd;
            shiftedOffsetEnd = offsetEnd*shiftMultiplicator+shiftAdd;
        }

        options.timeline = timeline;
        if(!options.fill) {
            options.fill = "both";
        }
        if(!options.easing) {
            options.easing = "ease";
        }
        options.rangeStart = { rangeName: 'cover', offset: CSS.percent(shiftedOffsetStart*100) };
        options.rangeEnd = { rangeName: 'cover', offset: CSS.percent(shiftedOffsetEnd*100) };
        el.animate(animation, options);
    }

    /*
    const year = bl.querySelector(".ctryear");
    addAnimation(0, year, {
        //opacity: [1, 0],
        transform: ["translateY(100vh)", "translateY(0)", "translateY(0)", "translateY(-100vh)"],
        offset: [0, 0.1, 0.99, 1] //custom timing offset
    }, 0, 1, {});
    */

    let itemIdx = 1;
    items.forEach(item => {
        const leftimg = item.querySelector(".leftimg");
        const rightimg = item.querySelector(".rightimg");
        addAnimation(itemIdx, leftimg, {
            //opacity: [1, 0],
            transform: ["translateY(100vh)", "translateY(0)", "translateY(0)", "translateY(-100vh)"],
            offset: [0, 0.2, 0.8, 1] //custom timing offset
        }, 0, 0.8, {});
        addAnimation(itemIdx, rightimg, {
            //opacity: [1, 0],
            transform: ["translateY(100vh)", "translateY(0)", "translateY(0)", "translateY(-100vh)"],
            offset: [0, 0.2, 0.8, 1] //custom timing offset
        }, 0.2, 1, {});
        itemIdx++;
    });
}


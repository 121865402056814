//npm install swiper
import Swiper from 'swiper/bundle';

import 'swiper/css/bundle';

import $ from "jquery";

$(function() {
    $(".bl-hgallery").each(function() {
        const $bl = $(this);
        initGallery($bl);
    });
});

function initGallery($bl) {
    const swiper = new Swiper($bl.find(".swiper").get(0), {
        loop: false,
        autoHeight: true,
        slidesPerView: "auto",
        spaceBetween: 20,

        scrollbar: {
            el: '.swiper-scrollbar'
        },
        mousewheel: {
            enabled: true,
            forceToAxis: true
        }
    });
}
